import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  isMenuActive : 0,
  isModalActive: 0
}

function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_MENU": {
        return {
            ...state,
            isMenuActive: state.isMenuActive === 0 ? 1 : 0,
        }
    }
    case "TOGGLE_MODAL": {
        return {
            ...state,
            isModalActive: state.isModalActive === 0 ? 1 : 0,
        }
    }
    default:
        throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider